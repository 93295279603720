import { string, object, mixed } from 'yup'

export const PHONE_REGEX = /^\+?1-?\s?(\()?[2-9]\d{2}(\))?-?\s?[2-9]\d{2}-?\s?\d{4}$/
export const PHONE_POSSIBLE = /^(?=.*[0-9])[()+\s0-9-]*$/

const firstName = string().required('Please enter a first name')
const lastName = string()

const email = string()
	.email('Please provide a valid email address')
	.required('Please provide an email address')
	.trim('Please remove all spaces.')

const emailSms = string()
	.test('is-required', 'Mobile number or email is required', function (value) {
		return value && value.trim() !== ''
	})
	.test('is-phone-or-email', 'Invalid mobile or email number', function (value) {
		if (value && value.trim() !== '') {
			const isPhone = string().matches(PHONE_POSSIBLE).isValidSync(value)
			if (isPhone && string().matches(PHONE_REGEX).isValidSync(value)) {
				return true // Valid phone number
			} else if (string().email().isValidSync(value)) {
				return true // Valid email address
			} else {
				const errorMessage = isPhone
					? 'Invalid sms, US or Canadian numbers only +1-xxx-xxx-xxxx'
					: 'Invalid email address'
				throw this.createError({
					path: this.path,
					message: errorMessage,
				})
			}
		}
		return false // Invalid phone number or email
	})

export const contributorSchema = object().shape({
	firstName,
	lastName,
	email,
})

export const creatorSchema = object().shape({
	firstName,
	emailSms,
})

export default contributorSchema
