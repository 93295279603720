import React, { useState, useEffect } from 'react'
import {
	VStack,
	InputGroup,
	InputRightElement,
	chakra,
	Flex,
	Button,
	SimpleGrid,
	Stack,
	Link,
	useDisclosure,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'
import Input from '../../../../molecules/controls/Input'
import PhoneNumber from '../../../../molecules/controls/PhoneNumber'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRSVPs } from '../../../../../hooks/useRSVPs'
import { Helmet } from 'react-helmet'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { addFlashMessage } from '../../../../../../api/app/behaviors/flash-messages'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'
import { verifyEmail } from '../../../../molecules/controls/InputEmail/utils'
import { postRSVP, updateRSVP } from '../../../../../../api/actions'
import { logCustomEvent } from '@vidday/utils'
import PropTypes from 'prop-types'
import { PHONE_REGEX, PHONE_POSSIBLE, contributorSchema, creatorSchema } from './schema'
import { useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { EmailRounded, LocalPhoneRounded, SendRounded, SupervisorAccountRounded } from '../../../../atoms/Icon'
import useProfile from '../../../../../hooks/useProfile'
import { convertToE164 } from '../../../../../utils'

/**
 * RSVP Form (used by the Creator and the participants )
 * @param {*}
 * @returns
 */
const RSVPForm = ({ onSubmit, isSubmitting, role, btnText, btnVariant }) => {
	const { register, watch, handleSubmit, control, errors } = useFormContext()
	const emailSmsValue = watch('emailSms')
	const [isPhoneNumber, setIsPhoneNumber] = useState()

	useEffect(() => {
		setIsPhoneNumber(PHONE_POSSIBLE.test(emailSmsValue))
	}, [emailSmsValue])

	// const [pickerSuported, setPickerSuported] = useState()
	// useEffect(() => {
	// 	setPickerSuported('contacts' in navigator && 'ContactsManager' in window)
	// }, [])
	// const contactPickerSMS = useDisclosure()
	// console.log("Supported", pickerSuported, contactPickerSMS.isOpen)

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			{role == 'public' && (
				<Stack direction="column" spacing={['1rem', '1rem']} w="full">
					<SimpleGrid columns={[1, 2]} spacing="1rem" w="full">
						<Input control={control} errors={errors} name="firstName" placeholder="First Name" />
						<Input
							{...register}
							control={control}
							errors={errors}
							name="lastName"
							placeholder="Last Name"
						/>
					</SimpleGrid>
					<SimpleEmail {...register} control={control} errors={errors} name="email" placeholder="Email" />
					<Stack w="full" direction={['column', 'row']} justifyContent="center">
						<Button maxH="40px" type="submit" variant={btnVariant || 'solid'} isLoading={isSubmitting}>
							<SendRounded mr=".25rem" />
							<span>{btnText || 'Send RSVP'}</span>
						</Button>
					</Stack>
				</Stack>
			)}

			{role == 'creator' && (
				<Stack
					direction={['column', 'row']}
					spacing="1rem"
					w="full"
					justifyContent={'space-evenly'}
					alignItems={'flex-start'}>
					<Input
						maxW={['full', '200px']}
						control={control}
						errors={errors}
						name="firstName"
						placeholder="First Name"
					/>
					<Stack direction={['column', 'row']} w="full">
						<InputGroup>
							<Input
								{...register}
								control={control}
								errors={errors}
								name="emailSms"
								placeholder="Mobile or Email"
							/>
							<InputRightElement width="4rem">
								{emailSmsValue.length > 0 ? (
									!isPhoneNumber ? (
										<EmailRounded color="gray.300" />
									) : (
										<LocalPhoneRounded color="gray.300" />
									)
								) : (
									<>
										<LocalPhoneRounded color="gray.300" />
										<EmailRounded color="gray.300" />
									</>
								)}
							</InputRightElement>
						</InputGroup>
						{/* pickerSuported && (
							<Button variant="link" onClick={contactPickerSMS.onToggle}>
								Open Contacts
							</Button>
						)}
						{isSafari && <a href="contacts://">Open Contacts</a>}
						{isAndroid && <a href="tel://">Open Contacts</a> */}
					</Stack>
					<Button
						variant={btnVariant || 'solid'}
						maxH="40px"
						w="full"
						maxW={['full', '150px']}
						type="submit"
						isLoading={isSubmitting}>
						<SupervisorAccountRounded mr=".25rem" />
						<span>{btnText || 'Invite'}</span>
					</Button>
				</Stack>
			)}
		</chakra.form>
	)
}

RSVPForm.defaultProps = {
	onSubmit: undefined,
	isSubmitting: false,
	role: 'public',
}

RSVPForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	role: PropTypes.oneOf(['creator', 'public']).isRequired,
}

/**
 * Form Wrapper used for RSVP on the contributor's side
 * @returns
 */
export const Contributor = ({ btnText, btnVariant, statusType = 'confirmed', addParticipant, ...rest }) => {
	const rsvps = useRSVPs()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { event, isOwner } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)

	/** Retrieve temporary user account if any */
	const tempUser = useTemporaryUserContext()

	/** Hold submit state of the form */
	const [isSubmitting, setIsSubmitting] = useState(false)

	/** Define form configuration and hooks  */
	const methods = useForm({
		defaultValues: { emailSms: '', firstName: '', lastName: '', email: '', sms: '', rsvp: '' },
		resolver: yupResolver(contributorSchema),
		reValidateMode: 'onChange',
	})

	const { setValue, setError, clearErrors, reset, register } = methods

	/**
	 * Let's preset the form values
	 * if we have a temporary user or rsvp data
	 * */
	useEffect(() => {
		if (!addParticipant) {
			if (tempUser) {
				setValue('email', tempUser.email || '')
				if (tempUser.profile?.firstName) {
					setValue('firstName', tempUser.profile.firstName || '')
				}
				if (tempUser.profile?.lastName) {
					setValue('lastName', tempUser.profile.lastName || '')
				}
				if (tempUser.profile?.sms) {
					setValue('sms', convertToE164(tempUser.profile.sms) || '')
				}
				if (tempUser.rsvp) {
					setValue('rsvp', tempUser.rsvp || '')
				}
			}
		}
	}, [tempUser])

	/* Submit for Contributors */
	const onSubmit = async (data) => {
		/** Let user know we're submitting the form */
		setIsSubmitting(true)
		/** Format Email address to keep things consistent */
		data.email = String(data.email).trim().toLowerCase()

		/** Prevent creator from registering to its own list */
		if (isOwner) {
			dispatch(addFlashMessage('info', "You can't be part of this list."))
			setIsSubmitting(false)
		} else {
			/**
			 * Let's check and make sure that the email isn't
			 * already contained in an existing RSVP
			 * */
			if (doesAlreadyExist(rsvps, [data.email, data.rsvp])) {
				setError('email', {
					type: 'error',
					message: 'You are already on the list.',
				})
				setIsSubmitting(false)
			} else {
				/**
				 * If it doesnt exist yet, we can proceed with the email verification.
				 */
				/** First, let's verify the email address is valid */
				const verify = await verifyEmail(data.email)
				setIsSubmitting(false)

				/** If email is not valid, let user know */
				if (!verify.isValid) {
					setError('email', {
						type: 'error',
						message: 'Invalid email, please check your entry.',
					})
				} else {
					/** If email is valid, let's proceed with RSVP */
					// Let's build our new rsvp object
					const rsvp = {
						deliveryEmail: data.email || undefined,
						deliverySms: tempUser?.profile?.sms ? convertToE164(tempUser.profile.sms) : undefined,
						firstName: data.firstName || undefined,
						lastName: data.lastName || undefined,
						eventId: event.id,
						status: statusType || undefined,
						notify: statusType == 'confirmed' ? true : false,
						notifyInvite: false,
						fromUpload: false,
					}

					// Put/Post the rsvp
					if (tempUser?.rsvp && !addParticipant) {
						// Put the rsvp
						dispatch(updateRSVP({ id: tempUser.rsvp, ...rsvp }))
					} else {
						// Post the rsvp, addParticipant is true lets make a new rsvp
						dispatch(postRSVP({ ...rsvp, notifyInvite: !addParticipant }))
					}

					// Log event
					logCustomEvent({
						action: 'rsvp',
						category: 'engagement',
						label: 'RSVP',
						identifier: event.id,
					})

					// Let's reset the form now.
					clearErrors()
					reset()

					// Navigate the user
					{
						statusType == 'confirmed' && navigate(handlers.public_rsvp_success)
					}
				}
			}
		}
	}

	return (
		<Flex w="full" alignItems="center" p={['1rem', '2rem']} minHeight={['0', '163px']} {...rest}>
			<FormProvider {...methods}>
				<RSVPForm
					onSubmit={onSubmit}
					isSubmitting={isSubmitting}
					role="public"
					btnText={btnText}
					btnVariant={btnVariant}
				/>
			</FormProvider>
		</Flex>
	)
}

Contributor.defaultProps = {
	addParticipant: false,
}

/**
 *
 * @param {array} collection
 * @param {array} checks
 * @returns {boolean} true false
 */
const doesAlreadyExist = (collection, checksArray) => {
	// Helper function to check if any of the checkOptions is present in the provided rsvp
	function containsCheckOption(rsvp) {
		return checksArray.some((checkOption) => Object.values(rsvp).some((value) => value === checkOption))
	}
	// Use the Array.some method to check if any item in the collection meets the condition
	return collection.some(containsCheckOption)
}

const Individual = ({ btnText, btnVariant, statusType, ...rest }) => {
	const rsvps = useRSVPs()
	const { email } = useAuthContext()
	const { sms } = useProfile()
	const { event } = useEventContext()

	// const isCreator = true
	// const recipients = useEventRecipients()
	const dispatch = useDispatch()

	/** Hold submit state of the form */
	const [isSubmitting, setIsSubmitting] = useState(false)

	/** Define form configuration and hooks  */
	const methods = useForm({
		defaultValues: { emailSms: '', firstName: '' },
		resolver: yupResolver(creatorSchema),
		reValidateMode: 'onChange',
	})

	const { setError, clearErrors, reset } = methods

	/* Submit for Creators */
	const onSubmit = async (data) => {
		/** Let user know we're submitting the form */
		setIsSubmitting(true)
		// Cleanup email address string
		const formattedEmail = String(data.emailSms).trim().toLowerCase()
		const formattedSms = convertToE164(data.emailSms)
		const isPhoneNumber = PHONE_POSSIBLE.test(data.emailSms)

		const sendRSVP = () => {
			/** If email is valid, let's proceed with RSVP */
			// Let's build our new rsvp object
			const rsvp = {
				firstName: data.firstName,
				lastName: data.lastName || '',
				deliveryEmail: isPhoneNumber ? undefined : formattedEmail,
				deliverySms: isPhoneNumber ? formattedSms : undefined,
				status: 'pending',
				eventId: event.id,
				notify: false,
				notifyInvite: true,
				fromUpload: false,
			}

			// Post the rsvp
			dispatch(postRSVP(rsvp))

			// Log event
			logCustomEvent({
				action: 'rsvp',
				category: 'engagement',
				label: 'RSVP',
				identifier: event.id,
			})

			// Let's reset the form now.
			clearErrors()
			reset({ emailSms: '', firstName: '' })
			setIsSubmitting(false)
		}

		/** Format Email address to keep things consistent */
		if (!isPhoneNumber) {
			/**
			 * If it doesnt exist yet, we can proceed with the email verification.
			 */
			/** First, let's verify the email address is valid */
			const verify = await verifyEmail(formattedEmail)
			/** If email is not valid, let user know */
			if (!verify.isValid) {
				setError('emailSms', {
					type: 'error',
					message: 'Invalid email, please check your entry.',
				})
				setIsSubmitting(false)
			} else if (doesAlreadyExist(rsvps, [formattedEmail])) {
				setError('emailSms', {
					type: 'error',
					message: 'This contact is already in the list.',
				})
				setIsSubmitting(false)
			} else {
				// All good!
				sendRSVP()
			}
		} else {
			/**
			 * Let's check and make sure that the emailSms isn't
			 * already contained in an existing RSVP
			 * */
			if (doesAlreadyExist(rsvps, [formattedSms])) {
				setError('emailSms', {
					type: 'error',
					message: 'This contact is already in the list.',
				})
				setIsSubmitting(false)
			} else {
				// All good!
				sendRSVP()
			}
		}
	}

	return (
		<>
			<Flex w="full" alignItems="center" p={['1rem', '2rem']} minHeight={['0', '163px']} {...rest}>
				<FormProvider {...methods}>
					<RSVPForm onSubmit={onSubmit} isSubmitting={isSubmitting} role="creator" />
				</FormProvider>
			</Flex>
		</>
	)
}

export default Individual
